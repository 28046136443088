import { SVGProps } from "react";

const ToastIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="18" height="18" rx="9" fill="#F8E5E5" />
    <path
      d="M4.9165 9L7.83317 11.9167L13.6665 6.08333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ToastIcon;
