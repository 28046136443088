import type { FlowbiteSidebarTheme } from "flowbite-react";

const Sidebar: { sidebar: FlowbiteSidebarTheme } = {
  sidebar: {
    root: {
      base: "h-full",
      collapsed: {
        on: "md:w-auto w-0 duration-300 transition-width",
        off: "w-64 transition-all duration",
      },
      inner: "h-full rounded bg-whiteBlack-100  dark:bg-gray-800",
    },
    collapse: {
      button:
        "group flex w-full items-center rounded-lg px-2 py-2.75 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
      icon: {
        base: "h-4.5 w-4.5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white",
        open: {
          off: "text-neutral-500",
          on: "",
        },
      },
      label: {
        base: "ml-2 text14Regular flex-1 whitespace-nowrap text-left",
        icon: "h-4.5 w-4.5 text-neutral-500",
      },
      list: "space-y-2 py-2",
    },
    cta: {
      base: "mt-6 rounded-lg p-4",
      color: {
        blue: "bg-blue-50 dark:bg-blue-900",
        dark: "bg-dark-50 dark:bg-dark-900",
        failure: "bg-red-50 dark:bg-red-900",
        gray: "bg-alternative-50 dark:bg-alternative-900",
        green: "bg-green-50 dark:bg-green-900",
        light: "bg-light-50 dark:bg-light-900",
        red: "bg-red-50 dark:bg-red-900",
        purple: "bg-purple-50 dark:bg-purple-900",
        success: "bg-green-50 dark:bg-green-900",
        yellow: "bg-yellow-50 dark:bg-yellow-900",
        warning: "bg-yellow-50 dark:bg-yellow-900",
      },
    },
    item: {
      base: "flex items-center justify-center rounded-lg px-2 py-2.75 text-neutral-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
      active: "text-primary-600 bg-primary-100",
      collapsed: {
        insideCollapse: "group w-full pl-8 transition duration-75",
        noIcon: "font-bold",
      },
      content: {
        base: "px-2 text14Regular flex-1 whitespace-nowrap",
      },
      icon: {
        base: "h-4.5 w-4.5 flex-shrink-0 text-neutral-500 transition duration-75 dark:text-gray-400 dark:group-hover:text-white",
        active: "text-primary-600",
      },
      label: "",
      listItem: "",
    },
    items: "",
    itemGroup:
      "flex flex-col gap-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700",
    logo: {
      base: "mb-5 flex items-center pl-2.5",
      collapsed: {
        on: "hidden",
        off: "self-center whitespace-nowrap text-xl font-semibold dark:text-white",
      },
      img: "mr-3 h-6 sm:h-7",
    },
  },
};

export default Sidebar;
