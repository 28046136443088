import { AttentionIcon, CloseIcon } from "@assets/icons";
import useToast from "@hooks/useToast";
import ToastIcon from "@icons/ToastIcon";
import { Toast, ToastProps } from "flowbite-react";
import React, { ReactNode } from "react";

export interface MonoToastProps extends ToastProps {
  type?: "success" | "danger";
  actionButton?: ReactNode;
  heading: string | ReactNode;
  subHeading?: string | ReactNode;
  leftIcon?: ReactNode;
}

const MonoToast = ({
  type = "success",
  actionButton,
  heading,
  subHeading,
  leftIcon,
}: MonoToastProps) => {
  const { hideToast } = useToast();
  return (
    <Toast
      className={`
        ${type === "success" ? "border border-green-600 bg-green-700" : "border border-red-400 bg-red-600"}
        ${subHeading ? "!md:min-w-[524px]" : ""}
      `}
    >
      {leftIcon ? (
        leftIcon
      ) : type === "success" ? (
        <ToastIcon className={"text-green-700"} />
      ) : (
        <AttentionIcon className={"text-whiteBlack-100"} width={18} height={18} />
      )}
      <div className="ml-2 flex flex-1 flex-col gap-0.5">
        <p className="text14Medium">{heading}</p>
        {subHeading ? (
          <p className="text14Regular overflow-hidden text-ellipsis whitespace-nowrap">
            {subHeading}
          </p>
        ) : null}
      </div>
      {actionButton ? actionButton : null}
      <CloseIcon
        width={14}
        height={14}
        className="cursor-pointer text-whiteBlack-100"
        onClick={hideToast}
      />
    </Toast>
  );
};

export default MonoToast;
