var UserPermissionsEnum = /* @__PURE__ */ ((UserPermissionsEnum2) => {
  UserPermissionsEnum2["viewer"] = "viewer";
  UserPermissionsEnum2["contributor"] = "contributor";
  UserPermissionsEnum2["manager"] = "manager";
  UserPermissionsEnum2["notAllowed"] = "notAllowed";
  return UserPermissionsEnum2;
})(UserPermissionsEnum || {});
var UserPermissionSectionsEnum = /* @__PURE__ */ ((UserPermissionSectionsEnum2) => {
  UserPermissionSectionsEnum2["affiliate"] = "affiliate";
  UserPermissionSectionsEnum2["business"] = "business";
  UserPermissionSectionsEnum2["calendar"] = "calendar";
  UserPermissionSectionsEnum2["customer"] = "customer";
  UserPermissionSectionsEnum2["dashboard"] = "dashboard";
  UserPermissionSectionsEnum2["inventory"] = "inventory";
  UserPermissionSectionsEnum2["manifest"] = "manifest";
  UserPermissionSectionsEnum2["order"] = "order";
  UserPermissionSectionsEnum2["booking"] = "booking";
  UserPermissionSectionsEnum2["payment"] = "payment";
  UserPermissionSectionsEnum2["report"] = "report";
  UserPermissionSectionsEnum2["template"] = "template";
  UserPermissionSectionsEnum2["user"] = "user";
  UserPermissionSectionsEnum2["waiver"] = "waiver";
  UserPermissionSectionsEnum2["widget"] = "widget";
  return UserPermissionSectionsEnum2;
})(UserPermissionSectionsEnum || {});
export {
  UserPermissionSectionsEnum,
  UserPermissionsEnum
};
