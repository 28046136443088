import { getDevicePlatform } from "./Common";
import { getCookie, removeCookie, setCookie } from "./CookieHelper";
const storeXTenant = (tenant) => {
  setCookie({ key: "x-tenant", value: tenant });
  return tenant;
};
const storeXDomain = (tenant) => {
  setCookie({ key: "x-sub-domain", value: tenant });
  return tenant;
};
const storeXDomainTimezone = (timezone) => {
  setCookie({ key: "x-domain-tz", value: timezone, params: { sameSite: "None", secure: true } });
  return timezone;
};
const storeXDomainCurrency = (currency) => {
  setCookie({ key: "x-domain-currency", value: currency });
  return currency;
};
const storeXReportsHideColums = (name, columns) => {
  setCookie({ key: name, value: JSON.stringify(columns) });
};
const storeXPlatform = () => {
  setCookie({
    key: "x-platform",
    value: getDevicePlatform(),
    params: { sameSite: "None", secure: true }
  });
};
const getToken = () => getCookie({ key: "token" });
const getDomainTimezone = () => getCookie({ key: "x-domain-tz" });
const getDomainCurrency = () => getCookie({ key: "x-domain-currency" });
const getSelectedTenant = () => getCookie({ key: "x-tenant" });
const removeSelectedTenant = () => removeCookie({ key: "x-tenant" });
const getSelectedDomain = () => getCookie({ key: "x-sub-domain" });
const getHiddenColumns = (name) => getCookie({ key: `${name}` });
const removeSelectedDomain = () => removeCookie({ key: "x-sub-domain" });
const removeDomainTimezone = () => removeCookie({ key: "x-domain-tz" });
const removeDomainCurrency = () => removeCookie({ key: "x-domain-currency" });
const getPlatform = () => getCookie({ key: "x-platform" });
export {
  getDomainCurrency,
  getDomainTimezone,
  getHiddenColumns,
  getPlatform,
  getSelectedDomain,
  getSelectedTenant,
  getToken,
  removeDomainCurrency,
  removeDomainTimezone,
  removeSelectedDomain,
  removeSelectedTenant,
  storeXDomain,
  storeXDomainCurrency,
  storeXDomainTimezone,
  storeXPlatform,
  storeXReportsHideColums,
  storeXTenant
};
