var SlotStatusEnum = /* @__PURE__ */ ((SlotStatusEnum2) => {
  SlotStatusEnum2["OPEN"] = "open";
  SlotStatusEnum2["CLOSED"] = "closed";
  return SlotStatusEnum2;
})(SlotStatusEnum || {});
var BookingStatusEnum = /* @__PURE__ */ ((BookingStatusEnum2) => {
  BookingStatusEnum2["CONFIRMED"] = "confirmed";
  BookingStatusEnum2["PENDING"] = "pending";
  BookingStatusEnum2["COMPLETED"] = "completed";
  return BookingStatusEnum2;
})(BookingStatusEnum || {});
export {
  BookingStatusEnum,
  SlotStatusEnum
};
