import Cookies from "js-cookie";
import { getDomainName } from "./Common";
const setLocalStorage = ({ key, value }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  }
};
const removeLocalStorage = ({ key }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    localStorage?.removeItem(key);
  }
};
const getLocalStorage = ({ key }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    return localStorage?.getItem(key);
  } else {
    return "";
  }
};
const setCookie = ({
  key,
  value,
  params
}) => {
  if (key !== "x-domain-tz") {
    Cookies.set(key, value, {
      domain: getDomainName(),
      ...params
    });
  }
  setLocalStorage({ key, value });
};
const removeCookie = ({
  key,
  params
}) => {
  Cookies.remove(key, {
    domain: getDomainName(),
    ...params
  });
  removeLocalStorage({ key });
};
const getCookie = ({ key }) => {
  let cookieValue = Cookies.get(key);
  if (cookieValue && key !== "x-domain-tz" && cookieValue != void 0) {
    return cookieValue;
  } else {
    const cookieValue2 = getLocalStorage({ key });
    return cookieValue2;
  }
};
export {
  getCookie,
  getLocalStorage,
  removeCookie,
  removeLocalStorage,
  setCookie,
  setLocalStorage
};
