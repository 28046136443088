import axiosInstance from "../libs/axios";
const login = async (body) => await axiosInstance.post("/api/v1/login", body);
const register = async (body) => await axiosInstance.post("/api/v1/register", body);
const registerUser = async (body, headers) => await axiosInstance.post("/api/v1/user", body, { headers });
const forgotPassword = async (body) => await axiosInstance.post("/api/v1/password/forgot", body);
const resetPassword = async (body) => await axiosInstance.post("/api/v1/password/reset", body);
const registerBusiness = async (body) => await axiosInstance.put("/api/v1/register/step-2", body);
const csrfCookie = async () => await axiosInstance.get("/sanctum/csrf-cookie");
const getAuthUser = async () => await axiosInstance.get("/api/v1/user/authenticated", {
  headers: {
    withCredentials: false
  }
});
const getConfig = async () => await axiosInstance.get("/api/v1/config", {
  headers: {
    withCredentials: false
  }
});
const getUserList = async () => await axiosInstance.get("/api/v1/user");
const logout = async () => await axiosInstance.post("/api/v1/logout");
const impersonate = async (token) => {
  return await axiosInstance.post("/api/v1/impersonate", { token });
};
export {
  csrfCookie,
  forgotPassword,
  getAuthUser,
  getConfig,
  getUserList,
  impersonate,
  login,
  logout,
  register,
  registerBusiness,
  registerUser,
  resetPassword
};
