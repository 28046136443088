import type { FlowbiteCheckboxTheme } from "flowbite-react";

const Checkbox: { checkbox: FlowbiteCheckboxTheme } = {
  checkbox: {
    root: {
      base: "h-4 w-4 rounded bg-neutral-50 outline-0 border border-neutral-300 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600",
    },
  },
};

export default Checkbox;
