import { Moment } from "moment";
import { CartProductType } from "./CartTypes";
import {
  CreateOrderResponseType,
  GetOrderDetailsResponseType,
  OrderCustomerType,
  OrderStatusTypes,
  ProductCategoryTypes,
} from "./OrderTypes";

export interface GetAllEventsRequestType {
  from_date: string;
  to_date: string;
  search_text?: string;
  categories?: string[];
  slot_status?: string;
  products?: string[];
  active_products?: string;
}

export interface EventAvailablilityADayType {
  slot_date: string;
  available: number;
  hold: number;
  booked: number;
  closed: number;
  bookings_count: number;
  is_reopenable: boolean;
  un_reopenable_reason: string | null;
  crew: {
    attachable_type: string;
    attachable_id: string;
    count: number;
  };
  slots: {
    availability_id: string;
    available: number;
    bifurcation: Record<
      string,
      {
        available: number;
        booked: number;
        capacity_per_quantity: number;
        closed: number;
        hold: number;
        total_capacity: number;
      }
    >;
    booked: number;
    bookings_count: number;
    closed: number;
    crew: {
      attachable_type: string;
      attachable_id: string;
      count: number;
    };
    end_date: string;
    end_time: string;
    hold: number;
    is_reopenable: boolean;
    is_slot_unavailable_due_to_past_time: boolean;
    local_end_time: string;
    local_start_time: string;
    reopenable_for: string | null;
    reopenable_reason: string;
    slot_id: string;
    slot_uuid: string;
    start_date: string;
    start_time: string;
    timezone: string;
    un_reopenable_reason: string | null;
    utc_start_time: string;
    utc_end_time: string;
  }[];
}

export interface GetDayLevelAvailabilityResponseType {
  availability: null;
  bookings: [];
  is_available: boolean;
  local_end_time: string;
  local_start_time: string;
  product: null;
  start_date: string;
  start_date_local: string;
}
export interface GetAllEventsResponseType {
  product_id: string;
  category: {
    id: string;
    name: string;
  };
  product_name: string;
  product_code: string;
  description: string;
  locations: any[];
  availability: EventAvailablilityADayType[];
  product_type: {
    id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    name: string;
    internal_type: ProductCategoryTypes;
    description: string;
  };
  is_rental: boolean;
  is_dynamic_rental: boolean;
}

export enum SlotStatusEnum {
  OPEN = "open",
  CLOSED = "closed",
}

export enum BookingStatusEnum {
  CONFIRMED = "confirmed",
  PENDING = "pending",
  COMPLETED = "completed",
}

export interface UpdateSlotStatusRequestType {
  slot_ids: Array<string>;
  status: SlotStatusEnum;
}

export interface UpdateSlotStatusResponseType {
  affected_bookings_count: number;
  bookings: {
    booking_number: string;
    cancellation_reason: string | null;
    cancelled_at: string | null;
    check_in_date: string | null;
    check_out_date: string | null;
    checked_in: number;
    created_at: string;
    id: string;
    is_dynamic_rental: false;
    is_rental: false;
    order: GetOrderDetailsResponseType;
    product: CartProductType;
    product_id: string;
    slot_end: string;
    slot_id: string;
    slot_start_at: string;
    status: string;
    units: any[];
    updated_at: string;
  }[];
}

export interface GetBookingCalendarEventsRequestType {
  from_date: string;
  to_date: string;
  search_text?: string;
  status?: string;
  products?: string[];
}

export interface GetBookingCalendarEventsByCrewResponseType {
  user_id: string;
  crew_member: {
    name: string;
    type: string;
  };
  bookings: {
    id: string;
    status: OrderStatusTypes;
    start_at: string;
    end_at: string;
    product: {
      id: string;
      name: string;
    };
  }[];
}

export interface BookingCalendarEventsByDateType {
  id: string;
  status: OrderStatusTypes;
  start_at: string;
  end_at: string;
  product: {
    id: string;
    name: string;
  };
  crew: {
    user_id: string;
    name: string;
    type: string;
    notes: string;
  }[];
}

export interface GetBookingCalendarEventsResponseType {
  booking_number: string;
  cancellation_reason: string;
  cancelled_at: string;
  cart_item_id: string;
  check_in_date: string;
  check_out_date: string;
  checked_in: number;
  created_at: string;
  crew_count: number;
  deleted_at: string;
  domain_id: string;
  id: string;
  order_item: {
    booking_participant_id: string;
    created_at: string;
    created_by: string;
    currency: string;
    deleted_at: string;
    description: string;
    domain_id: string;
    id: string;
    order_id: string;
    parent_id: string;
    product_id: string;
    slot_id: string;
    status: string;
    subtotal_in_cents: number;
    tenant_id: string;
    total_in_cents: number;
    total_levies_in_cents: number;
    total_offer_in_cents: number;
    updated_at: string;
  };
  order_item_id: string;
  product_availability_slot: {
    assets: null;
    availability: {
      all_day: number;
      assets: string[];
      available_days: number[];
      created_at: string;
      created_by: string;
      crew_count: number;
      cut_off: string;
      cut_off_type: string;
      deleted_at: string;
      domain_id: string;
      duration: number;
      duration_type: string;
      end_date: string;
      end_time: string;
      id: string;
      increment: number;
      increment_type: string;
      interval_between_slots: string;
      interval_between_slots_type: string;
      maintenance: string;
      maintenance_type: string;
      name: string;
      product_id: string;
      start_date: string;
      start_time: string;
      tenant_id: string;
      updated_at: string;
    };
    available: number;
    booked: number;
    bookings: {
      booking_number: string;
      cancellation_reason: string;
      cancelled_at: string;
      cart_item_id: string;
      check_in_date: string;
      check_out_date: string;
      checked_in: number;
      created_at: string;
      crew_count: number;
      deleted_at: string;
      domain_id: string;
      id: string;
      order_item_id: string;
      product_id: string;
      slot_id: string;
      slot_uuid: string;
      status: string;
      tenant_id: string;
      updated_at: string;
    }[];
    cancelled_at: string;
    created_at: string;
    crew_count: number;
    deleted_at: string;
    domain_id: string;
    end_at: string;
    hold: number;
    id: string;
    is_real_time_created: number;
    local_end_time: string;
    local_start_time: string;
    product: {
      domain: string;
      has_add_on: number;
      product_type_id: string;
      type: { id: string; name: string; internal_type: string };
      id: string;
      internal_type: string;
      name: string;
    };
    product_availability_id: string;
    product_id: string;
    start_at: string;
    tenant_id: string;
    updated_at: string;
  };
  product_id: string;
  slot_id: string;
  slot_uuid: string;
  status: string;
  tenant_id: string;
  updated_at: string;
}

export interface GetBookingCalendarEventsByProductResponseType {
  product_id: string;
  product_name: string;
  bookings: {
    id: string;
    status: OrderStatusTypes;
    start_at: string;
    end_at: string;
    crew: {
      user_id: string;
      name: string;
      type: string;
      notes: string;
    }[];
  }[];
}

// TODO: Need to refactor the response type after the API is refectored
export interface GetBookingsFromSlotIdResponseType {
  id: string;
  product_id: string;
  product_name: string;
  category: string;
  order: CreateOrderResponseType["order"];
  slot: any;
  customer: OrderCustomerType;
  checked_in: number;
  cancelled_at: string | null;
  cancellation_reason: string | null;
  check_in_date: string | null;
  check_out_date: string | null;
  slot_id: string;
  status: OrderStatusTypes;
  created_at: string;
  updated_at: string;
  booking_number: string;
  crew: {
    attachable_type: string;
    attachable_id: string;
    count: number;
  };
  order_commission: string | null;
}
