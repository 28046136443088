import apiAxios, { axiosPublic } from "../libs/axios";
import { getSelectedDomain } from "../utils/TenantHelper";
const domain = getSelectedDomain();
const getTenant = async () => await apiAxios.get("/api/v1/tenant");
const getDomainDetails = async (domainId) => await apiAxios.get(`/api/v1/domain/${domainId ?? domain}`);
const getDomainBasicDetails = async (config = {}) => await axiosPublic.get(`/api/v1/public/domain/details`, config);
const updateBusinessProfile = async (body) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] && !Array.isArray(body[key])) {
      formData.append(key, body[key]);
    }
    if (key === "logo" && Array.isArray(body[key]) && body[key]?.length === 0) {
      formData.append(key, "null");
    }
    if (Array.isArray(body[key])) {
      body[key].forEach((item) => {
        if (item.file) {
          formData.append(`${key}`, item.file);
        }
      });
    }
  });
  await apiAxios.post(`/api/v1/domain/${domain}/profile`, formData);
};
const updateRegionalSettings = async (body) => await apiAxios.put(`/api/v1/domain/${domain}/region`, body);
const updateScheduleSettings = async (body) => await apiAxios.put(`/api/v1/domain/${domain}/schedule`, body);
const domainOrderField = async (id, body) => await apiAxios.post(`api/v1/domain/${id}/store-fields`, body);
const getDomainOrderFieldbyId = async (id) => await apiAxios.get(`/api/v1/domain/${id}/fields`);
const deleteDomainOrderField = async (domainId, fieldId) => await apiAxios.delete(`/api/v1/domain/${domainId}/fields/${fieldId}`);
const sortDomainOrderField = async (domainId, body) => await apiAxios.post(`/api/v1/domain/${domainId}/sort-fields`, body);
export {
  deleteDomainOrderField,
  domainOrderField,
  getDomainBasicDetails,
  getDomainDetails,
  getDomainOrderFieldbyId,
  getTenant,
  sortDomainOrderField,
  updateBusinessProfile,
  updateRegionalSettings,
  updateScheduleSettings
};
