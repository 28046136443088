import axios from "axios";
import axiosRetry from "axios-retry";
import { UserChannel } from "../../types/AuthServicesTypes";
import {
  getPlatform,
  getSelectedDomain,
  getSelectedTenant,
  getToken,
} from "../../utils/TenantHelper";
import Sentry from "../sentry";

let API = process.env.NEXT_PUBLIC_API_URL || process.env.EXPO_PUBLIC_API_URL;
const APIS_TO_RETRY = ["/api/v1/order/payment"];

export const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    Accept: "application/json",
    "X-Tenant": "",
    withCredentials: true,
  },
});

export const axiosPublic = axios.create({
  baseURL: API,
  headers: {
    Accept: "application/json",
    "X-Tenant": "",
    "X-Channel": "",
    "X-Subdomain": "",
  },
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryCondition: (error) => {
    return error.response?.config.url
      ? APIS_TO_RETRY.includes(error.response.config.url) && error.response.status !== 200
      : false;
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = getToken();
  const tenant = getSelectedTenant();
  const subDomain = getSelectedDomain();
  const platform = getPlatform();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (tenant) {
    config.headers["X-Tenant"] = tenant;
  }
  if (subDomain) {
    config.headers["X-Subdomain"] = subDomain;
  }
  if (platform) {
    config.headers["X-Platform"] = platform;
  }
  if (!config.headers["X-Channel"]) {
    config.headers["X-Channel"] = UserChannel.OFFLINE;
  }

  return config;
});

axiosPublic.interceptors.request.use(async (config) => {
  const platform = getPlatform();
  let affiliateId = null;
  let affiliateCode = null;
  if (typeof window !== "undefined") {
    const subDomain = window.location.hostname.split(".", 1)[0];
    if (subDomain) {
      config.headers["X-Subdomain"] = subDomain;
    }
    try {
      const url = new URL(window.location?.href);
      const searchParams = url.searchParams;
      // Google signin for customer login
      if (searchParams.get("state")) {
        const domainNameAsString = window.atob(searchParams.get("state") as string);
        if (domainNameAsString) {
          const domainName = JSON.parse(domainNameAsString)?.domain;
          if (domainName) {
            config.headers["X-Subdomain"] = domainName;
          }
        }
      }

      // Retrieve specific parameters
      affiliateId = searchParams.get("affiliateID");
      if (!affiliateId) {
        affiliateId = searchParams.get("attachable_id");
      }
      affiliateCode = searchParams.get("affiliate_code") || searchParams.get("affiliateID");
    } catch (e) {
      Sentry.captureException(e);
      console.error({ e });
    }
  }

  if (platform) {
    config.headers["X-Platform"] = platform;
  }
  if (affiliateCode) {
    config.headers["X-AffiliateCode"] = affiliateCode;
  }
  if (affiliateCode) {
    config.headers["X-Channel"] = UserChannel.AFFILIATE;
  } else if (!config.headers["X-Channel"]) {
    config.headers["X-Channel"] = UserChannel.ONLINE;
  }
  return config;
});

axiosPublic.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export const setAxiosToken = (token: string | undefined | null) => {
  if (token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.Authorization;
  }
};

export const setAxiosTenant = (tenant: string | undefined | null) => {
  if (tenant) {
    axiosInstance.defaults.headers["X-Tenant"] = tenant;
  } else {
    delete axiosInstance.defaults.headers["X-Tenant"];
  }
};

export const setAxiosSubDomain = (subDomain: string | undefined | null) => {
  if (subDomain) {
    axiosInstance.defaults.headers["X-Subdomain"] = subDomain;
  } else {
    delete axiosInstance.defaults.headers["X-Subdomain"];
  }
};

export const setAxiosPlatform = (platform: string | undefined | null) => {
  if (platform) {
    axiosInstance.defaults.headers["X-Platform"] = platform;
  } else {
    delete axiosInstance.defaults.headers["X-Platform"];
  }
};

export const setAxiosHeader = (key: string, value: string) => {
  axiosInstance.defaults.headers.common[key] = value;
};

export const removeAxiosHeader = async () => {
  delete axiosInstance.defaults.headers["X-Tenant"];
  delete axiosInstance.defaults.headers["X-Channel"];
  delete axiosInstance.defaults.headers["X-Subdomain"];
  delete axiosInstance.defaults.headers.Authorization;
};

export const setAxiosBaseURL = (value: any) => {
  axiosInstance.defaults.baseURL = value;
  axiosPublic.defaults.baseURL = value;
};

export default axiosInstance;
