var UserPlatform = /* @__PURE__ */ ((UserPlatform2) => {
  UserPlatform2["DESKTOPWEB"] = "desktop-website";
  UserPlatform2["MOBILEWEB"] = "mobile-website";
  UserPlatform2["IOS"] = "mobile-ios";
  UserPlatform2["ANDROID"] = "mobile-Android";
  return UserPlatform2;
})(UserPlatform || {});
var UserChannel = /* @__PURE__ */ ((UserChannel2) => {
  UserChannel2["OFFLINE"] = "offline";
  UserChannel2["AFFILIATE"] = "affiliate";
  UserChannel2["ONLINE"] = "online";
  return UserChannel2;
})(UserChannel || {});
export {
  UserChannel,
  UserPlatform
};
